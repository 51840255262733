var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-btn-toggle',{staticClass:"ma-2",attrs:{"divided":"","dense":"","mandatory":""},on:{"change":_vm.toggled},model:{value:(_vm.sales_post),callback:function ($$v) {_vm.sales_post=$$v},expression:"sales_post"}},[_c('v-btn',[_vm._v("Adex orders")]),_c('v-btn',[_vm._v("Courier Orders")])],1)],1),_c('v-col',[_c('v-btn-toggle',{staticClass:"ma-2",attrs:{"divided":"","dense":"","mandatory":""},on:{"change":_vm.toggled},model:{value:(_vm.selected_liquidation),callback:function ($$v) {_vm.selected_liquidation=$$v},expression:"selected_liquidation"}},[_c('v-btn',[_vm._v("Not Liquidated")]),_c('v-btn',[_vm._v("Liquidated")])],1)],1),_c('v-col'),_c('v-col',[_c('v-select',{staticClass:"pa-2",attrs:{"outlined":"","filled":"","dense":"","items":[
          'This Month',
          'Last Month',
          'Today',
          'Yesterday',
          'This Year',
          'Last Year',
          'Custom Date' ]},on:{"input":function (dateRange) { return _vm.dateRangeChanged(dateRange); }},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"100px","width":"200px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Custom Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.dateRangeChanged(_vm.date, 'customDate')}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-end",attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4',"flat":"","tile":""}}),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Transactions"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by.sync":"order_status","sort-desc.sync":"true","search":_vm.search,"items-per-page":20,"headers":_vm.headers,"items":_vm.computedOrders},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('v-row',{staticStyle:{"width":"300px"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('h4',[_vm._v("Orders: "+_vm._s(_vm.totalOrders))])]),_c('v-col',[_c('h4',[_vm._v("Total Price: "+_vm._s(_vm.sum)+" Lek")])])],1)]},proxy:true},{key:"item.order_status",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:item.color},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.orderStatusToText(item.order_status))+" ")])])]}},{key:"item.deadline",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.deadline)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.deadline))+" ")])])]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"60%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":""}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.id)+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(_vm._s(_vm.translate("Order"))+" #"+_vm._s(item.id))]),_c('v-card-text',[_c('OrderInfo',{attrs:{"currency":_vm.currency,"order":item},on:{"setNewData":_vm.setNewData}})],1),_c('v-divider')],1)],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'edit_order', query: { id: item.id } }}},[_c('v-icon',{staticStyle:{"color":"var(--v-warning-base)"},attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"var(--v-error-base)"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();item.delete_dialog = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.translate("Are you sure want to delete Order"))+" "+_vm._s(item.id)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleDelete(item.id)}}},[_vm._v(_vm._s(_vm.translate("OK")))]),_c('v-spacer')],1)],1)],1)]}}])})],1),_c('div',{staticClass:"text-center"},[_c('downloadExcel',{staticClass:"elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile",attrs:{"data":_vm.orders,"fields":{
      order_id: 'id',
      order_status: 'order_status',
      order_created_at: 'order_created_at',
      deadline: 'deadline',
      order_completed_at: 'order_completed_at',
      magazine: 'magazine_name',
      created_by_user_id: 'created_by_user_id',
      driver_id: 'driver_id',
      delivery_fee: 'delivery_fee',
      driver_name: 'driver_name',
      driver_phone_number: 'driver_phone_number',
      client_name: 'client_name',
      client_phone_number: 'client_phone',
      client_address: 'client_address',
      client_lat: 'client_lat',
      client_lng: 'client_lng',
      order_status: 'order_status_formatted',
      reason: 'refuse_reason',
      origin: 'origin_type',
      total_price: 'total_price',
      profit: 'profit',
    }}},[_vm._v("   Export Excel    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }